<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex lg10 pt-10>
        <v-card elevation="5">
          <v-layout wrap>
            <v-flex lg12 py-6>
              <v-layout>
                <v-flex lg3 pt-3 pb-3 class="text-center">
                  <span style="font-size: 25px; font-weight: bolder"
                    >REPORT</span
                  >
                </v-flex>

                <v-flex lg3 pt-3 pb-3 pl-4>
                  <v-form @submit.prevent="validateInput">
                    <v-autocomplete
                      clearable
                      dense
                      style="border-radius: 0%"
                      outlined
                      label="IB"
                      color="black"
                      :items="list"
                      v-model="ib"
                      item-text="accName"
                      item-value="_id"
                      item-color="#FF1313"
                      hide-details
                      class="custom-autocomplete mainfont"
                    >
                      <template v-slot:append>
                        <v-icon color="black">mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-form>
                </v-flex>

                <v-flex lg3 pt-3 pb-3 pl-4>
                  <v-form @submit.prevent="validateInput">
                    <v-autocomplete
                      clearable
                      dense
                      outlined
                      label="Payment Status"
                      color="black"
                      :items="paymentstatusitems"
                      v-model="paymentstatus"
                      item-color="#FF1313"
                      hide-details
                      class="custom-autocomplete mainfont"
                    >
                      <template v-slot:append>
                        <v-icon color="black">mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-form>
                </v-flex>

                <v-flex lg3 pt-3 pb-3 pl-4>
                  <v-form @submit.prevent="validateInput">
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromdate"
                          label="From Date"
                          readonly
                          v-bind="attrs"
                          outlined
                          v-on="on"
                          style="border-radius: 0%"
                          dense
                          hide-details
                          class="custom-autocomplete mainfont"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromdate"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-form>
                </v-flex>

                <v-flex lg3 pt-3 pb-3 pl-4>
                  <v-form @submit.prevent="validateInput">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="todate"
                          label="To Date"
                          readonly
                          v-bind="attrs"
                          outlined
                          v-on="on"
                          style="border-radius: 0%"
                          dense
                          hide-details
                          class="custom-autocomplete mainfont"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        @input="menu2 = false"
                        :min="fromdate"
                      ></v-date-picker>
                    </v-menu>
                  </v-form>
                </v-flex>

                <v-flex lg3 pa-3 class="text-center">
                  <v-btn>
                    <a
                      target="_blank"
                      :href="`https://ibapi.forest.kerala.gov.in/admin/excel/report/v1?accid=${this.ib._id}&fromDate=${this.fromdate}&toDate=${this.todate}&paymentStatus=${this.paymentstatus}`"
                      class="downlink"
                      style="text-decoration: none"
                    >
                      <span
                        style="
                          font-size: 16px;
                          font-family: poppinssemibold;
                          color: black;
                          text-decoration: none;
                        "
                      >
                        Download
                      </span>
                      <v-icon color="black"> mdi-download</v-icon>
                    </a>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      //   year: ['2000','2001'],
      years: Array.from({ length: 2025 - 2000 }, (_, index) =>
        (2000 + index).toString()
      ),
      ib: "",
      list: [],
      year: "2024",
      fromdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      todate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,
      paymentstatusitems: ["Success"],
      paymentstatus: "",
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    fromdate(newVal) {
      if (new Date(this.todate) < new Date(newVal)) {
        this.todate = newVal; // Update `todate` to match `fromdate`
      }
    },
  },
  methods: {
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/all/accomodations/report",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {},
      })
        .then((response) => {
          this.list = response.data.data;
          this.ib = response.data.data[0];
          this.totalData = response.data.totalLength;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    downloadPdf() {
      // console.log("ids", this.ib);
      // console.log("year", this.year);
    },
  },
};
</script>
